<template>
    <div>
        <el-card>
            <div class="global-search-single">
                <div class="single-left" v-if="hasPerm(['setting.sensitive_words.store'])">
                    <el-button type="primary" @click="addSensitive">添加敏感字</el-button>
                    <el-button type="primary" @click="batchAddSensitive">批量添加敏感字</el-button>
                </div>
                <div class="single-right">
                    <div class="search-item">
                        <el-input placeholder="请输入敏感字" v-model="inputSearch"></el-input>
                    </div>
                    <el-button type="primary" @click="getTensitive">筛选</el-button>
                </div>
            </div>
            <el-divider></el-divider>
            <el-table :data="tensitiveList" style="width: 100%;margin-top: 20px;">
                <el-table-column label="ID" prop="id"></el-table-column>
                <el-table-column label="标签名称" prop="name"></el-table-column>
                <el-table-column label="添加时间" prop="created_at"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editTensitive(scope.row)"
                            v-if="hasPerm(['setting.sensitive_words.update'])">编辑</el-button>
                        <el-button type="text" @click="deleteTensitive(scope.row)"
                            v-if="hasPerm(['setting.sensitive_words.delete'])">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @size-change="handleSizeChange" :hide-on-single-page="false"
                    @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40]"
                    :page-size="eachPage" layout="total, sizes, prev, pager, next, jumper" :pager-count="5"
                    :total="total_entry">
                </el-pagination>
            </div>
        </el-card>
        <el-dialog :title="dialogTitle" :visible.sync="dialogIsShow" width="30%">
            <div class="dialog-box">
                <el-form :rules="dialogRules" ref="dialogForm" :model="dialogForm" label-width="auto">
                    <el-form-item label="敏感词" prop="name">
                        <el-input v-model="dialogForm.name" maxlength=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="dialogSubmit" :loading="submitLoading">提 交</el-button>
            </div>
        </el-dialog>

        <diy-import-more ref="diyImportMore" dialogImportUrl="/content/sensitive_words/import"
            dialogTemplateUrl="sensitive.words.import.template" @dialogImportCallback="importCallback"></diy-import-more>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tensitiveList: [],
            currentPage: 1,
            eachPage: 10,
            total_entry: 0,
            inputSearch: '',
            dialogTitle: '',
            dialogIsShow: false,
            submitLoading: false,
            submitType: '',
            dialogForm: {
                name: '',
                id: 0
            },
            dialogRules: {
                name: {
                    required: true,
                    message: "请输入敏感词",
                    trigger: "blur",
                }
            },
        }
    },
    created() {
        // 获取敏感词列表
        this.getTensitive()
    },
    methods: {
        getTensitive() {
            let _data = {
                search: [{
                    key: "name",
                    value: this.inputSearch,
                    op: "search"
                }],
                order: [],
                page: this.currentPage,
                limit: this.eachPage
            };
            this.$get(this.$apis.getSensitive, _data).then(res => {
                //console.log(res, 'getSensitive');
                if (res.code == 200) {
                    this.tensitiveList = res.data.list;
                    this.total_entry = res.data.total;
                } else {
                    this.common.message(this, res.message);
                }
            }).catch(err => {
                // //console.log(err)
                this.common.message(this, err.message);
            });
        },
        handleSizeChange(val) {
            this.eachPage = val;
            this.getTensitive();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTensitive();
        },
        editTensitive(row) {
            //console.log(row, 'row');
            this.dialogForm.name = row.name;
            this.dialogForm.id = row.id;
            this.dialogTitle = '编辑敏感词';
            this.dialogIsShow = true;
            this.submitType = 'edit'
        },
        addSensitive() {
            this.dialogForm.name = '';
            this.dialogForm.id = '';
            this.dialogTitle = '添加敏感词'
            this.dialogIsShow = true
            this.submitType = 'add'
        },
        batchAddSensitive() {
            this.$refs.diyImportMore.importMore = true;
        },
        closeDialog() {
            this.dialogIsShow = false
            this.$refs['dialogForm'].resetFields()
        },
        dialogSubmit() {
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    // 整理数据
                    this.submitLoading = true

                    let _data = {
                        name: this.dialogForm.name
                    }
                    if (this.submitType == 'add') {
                        this.$post(this.$apis.addSensitive, _data).then(res => {
                            if (res.code == 200) {
                                this.submitLoading = false
                                this.dialogIsShow = false
                                this.$refs['dialogForm'].resetFields()
                                this.$message({
                                    type: 'success',
                                    duration: 1500,
                                    message: '添加成功',

                                });
                                this.getTensitive()
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    } else {
                        this.$put(this.$apis.editSensitive + this.dialogForm.id, _data).then(res => {
                            if (res.code == 200) {
                                this.submitLoading = false
                                this.dialogIsShow = false
                                this.$refs['dialogForm'].resetFields()
                                this.$message({
                                    type: 'success',
                                    duration: 1500,
                                    message: '编辑成功',

                                });
                                this.getTensitive()
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    }

                } else {
                    //console.log('error submit!!');
                    return false;
                }
            });
        },
        deleteTensitive(row) {
            this.$confirm('您确定删除该敏感词吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$delete(this.$apis.editSensitive + row.id).then(res => {
                    // //console.log(res)
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            duration: 1500,
                            message: '删除成功',

                        });
                        this.getTensitive();

                    } else {
                        this.common.message(this, res.message);
                    }
                }).catch(err => {
                    // //console.log(err)
                    this.common.message(this, err.message);
                });

            })
        },
        importCallback(res) {
            //console.log(res, 'importCallback');
            // 成功导入，刷新列表
            this.getTensitive()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-divider--horizontal {
    margin: 20px 0 !important;
}

::v-deep .el-button.el-button--text {
    padding: 0 16px 0 0 !important;
    color: var(--fontColor) !important;
    border-right: 1px solid var(--fontColor) !important;
    border-radius: 0;

    &:last-child {
        border: 0 !important;
        padding-left: 5px !important;

    }
}

::v-deep .el-button.el-button--text:hover {
    color: var(--fontColor) !important;
}


.dialog-box {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    .el-input {
        width: 275px;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
</style>